import { Route, Routes } from "react-router-dom";
import React from "react";
import Product from "./pages/Product";
import Orders from "./pages/Order";
import CompletionPage from "./pages/CompletionPage";

function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route element={<Product />} path="/"/>
        <Route element={<Orders />} path="/orders"/>
        <Route element={<CompletionPage />} path="/completion"/>
      </Routes>
    </React.Fragment>
  );
}

export default App;
