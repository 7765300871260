import axios from "axios";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3001"
      : "https://srhe29trb2.execute-api.us-east-2.amazonaws.com/dev/stripe",
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_STRIPE_SERVER_ACCESS_TOKEN
        : process.env.REACT_APP_STRIPE_SERVER_ACCESS_TOKEN2
    }`,
  },
});

export const stripeApi = instance;
