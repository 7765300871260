import React, { useEffect, useRef, useState } from "react";
import Header from "../components/layout/Header";
import SubscriptionCard from "../components/UI/ProductCard";
import { Volume2, VolumeX } from "lucide-react";
import FAQAccordion from "../components/UI/FAQAccordian";

import { subscription, reasons } from "../assets/ProductData";
import Footer from "../components/layout/Footer";
import { trackEvent } from "../utils/tracker";

function Product() {
  const videoContainerRef = useRef(null);
  const videoRef = useRef(null);
  const mainRef = useRef(null);
  const volumeButtonRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [muted, setMuted] = useState(true);

  const handleScroll = () => {
    const scrollPosition = mainRef.current.scrollTop;
    const videoContainer = videoContainerRef.current;
    const video = videoRef.current;
    const volumeButton = volumeButtonRef.current;

    if (!videoContainer || !video) {
      return;
    }
    const maxScroll = 500;
    const scrollPercentage = Math.min(scrollPosition / maxScroll, 1);
    const maxMargin = 48;
    const margin = scrollPercentage * maxMargin;

    videoContainer.style.paddingLeft = `${margin}px`;
    videoContainer.style.paddingRight = `${margin}px`;
    video.style.borderRadius = `${margin}px`;
    volumeButton.style.marginRight = `${margin}px`;
  };

  return (
    <main
      ref={mainRef}
      onScroll={handleScroll}
      className="bg-white min-h-[100dvh] text-black flex flex-col overflow-auto scrollbar-sleek"
    >
      <Header />

      <div
        ref={videoContainerRef}
        className="box-border max-w-full mb-28 max-h-[900px] relative"
      >
        <video
          ref={videoRef}
          src="/videos/pookie_toy_demo.mp4"
          autoPlay
          muted={muted}
          loop
          onLoadedMetadata={() => setVideoLoaded(true)}
          playsInline
          controls={false}
          className="transition-all duration-300 ease-out w-full otline-none h-full object-cover"
        />
        {videoLoaded ? (
          <button
            ref={volumeButtonRef}
            onClick={() => {
              if (!muted){
                trackEvent("user_listen_to_pookie_pet_video")
              }
              setMuted(!muted)
            }}
            className="bg-[#ffffff9d] rounded-full p-2 md:p-4 absolute top-10 right-10"
          >
            {muted ? <VolumeX size={15} /> : <Volume2 size={15} />}
          </button>
        ) : null}
      </div>
      <section className="mx-auto px-12 py-8 w-full max-w-7xl">
        <h2 className="text-[32px] md:text-[40px] lg:text-[50px] font-bold my-10 sm:my-12 md:my-14 lg:my-16">
          Choose Your Pookie Friend
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 py-6 rounded-lg">
          {subscription.map((product) => (
            <SubscriptionCard key={product.id} product={product} />
          ))}
        </div>
      </section>
      <section className="mx-auto px-12 py-8 w-full max-w-7xl ">
        <h2 className="text-[32px] md:text-[40px] lg:text-[50px] font-bold mb-8">
          FAQs
        </h2>
        <div className="space-y-4">
          {reasons.map((reason, index) => (
            <FAQAccordion
              key={index}
              question={reason.title}
              answer={reason.description}
            />
          ))}
        </div>
      </section>

      <Footer />
    </main>
  );
}

export default Product;
