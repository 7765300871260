const NavBar = () => {
  return (
    <header className="bg-white px-12 py-10 sm:py-12 md:py-14 lg:py-16 flex items-center gap-4">
      <div className="container flex flex-col md:flex-row md:justify-between md:items-center mx-auto">
        <div className="flex flex-col">
          <h1 className="text-[40px] md:text-[50px] lg:text-[60px] font-semibold text-black">
            Pookie
          </h1>
          <p className="-translate-y-3 tracking-widest text-sm font-thin md:self-end">by Egg Robotoics</p>
        </div>
        <p className="text-2xl font-semibold text-black">your friendly neighborhood plushy</p>
      </div>
    </header>
  );
};

export default NavBar;
