export const reasons = [
  {
    title: "Is this like ChatGPT but with voice?",
    description:
      "Nope- it's a class apart, well above ChatGPT. Pookie has realistic voice, personality, and conversational capabilities while offering a level of educational intelligence matching GPT-4o and o1.",
  },
  {
    title: "Does it have gaudrails around what it'll talk to my child about?",
    description:
      "Absolutely! No matter what, you'll never find Pookie say anything a good friend or a good teacher wouldn't say.",
  },
  {
    title: "Does it have unlimited talking time?",
    description:
      "Yes- that's why we have a heavily discounted subscription pricing model (instead of a $600 one time purchase) to make sure Pookie is accessible to everyone for as long as they'd like to use it.",
  },
  {
    title: "Does Pookie have to be returned if we cancel subscription?",
    description:
      "No- you can keep it! Pookie will stop talking if the subscription is cancelled/ends, but you can keep the device. If that day ever comes, checkout our latest Pookies of the day- we're always offering something new! ",
  },
  {
    title: "Who's behind Pookie?",
    description:
      "Egg (Egg Robotics) is the parent company behind Pookie, founded by Dartmouth and Stanford researchers to offer world-class AI devices globally. Pookie is the first of its kind, in a line-up of many more to come!",
  },
  {
    title : "Will you service Pookie? How often?",
    description : "Pookie rarely needs servicing but we have home-servicing free of cost twice a year to checkup on your Pookie's health. We also offer free replacements for reasonable damages as long as you're on a subscription plan. "
  },
  {
    title : "I want to buy a new Pookie- what do I do? ",
    description : "Just ask us! No need to pay extra- as long as you have an ongoing subscription, we'll upgrade your Pookie device to the latest one under the same plan once/year if you'd like. "
  },
  {
    title : "How is my data stored? What about COPPA?",
    description : "Pookie and Egg Robotics take COPPA very seriously, and more information along with controls are offered in a dedicated app provided to parents at the time of purchase."
  }
];



export const subscription = [
  {
    id: 1,
    name: "Pookie",
    description: ["AI (with personality & guardrails)", "Parental Monitoring App", "Base Speaker"],
    price: 9.99,
    image: "/images/products/pookie_kitten.jpg",
  },
  {
    id: 2,
    name: "Pookie Pro",
    description: ["AI (with personality & guardrails)", "Parental Monitoring App", "Dolby Audio"],
    price: 15.99,
    image: "/images/products/pookie_puppy.jpg",
  },
  {
    id: 3,
    name: "Pookie Pro Max",
    description: [
      "AI (with personality & guardrails)",
      "Parental Monitoring App",
      "Dolby Audio",
      "Physical Capabilities (touch & haptics)",
    ],
    price: 29.99,
    image: "/images/products/pookie_exotic.jpg",
  },
];
