import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setSubscription } from "../../redux/slice/subscription";
import { useNavigate } from "react-router-dom";
import { trackEvent } from "../../utils/tracker";
const ProductCard = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cardRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  const handleAdoptNow = async () => {
    try {
      dispatch(setSubscription(product));
      navigate("/orders");
      trackEvent("user_clicked_adopt_now", {
        price: product.price,
        name: product.name,
      });
    } catch (err) {
      alert(err?.message);
    }
  };

  return (
    <div
      ref={cardRef}
      className={`bg-white rounded-lg shadow-md overflow-hidden border border-gray-200 flex flex-col transition-all duration-500 ${
        isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
      }`}
    >
      <img
        src={product.image}
        alt={product.name}
        className="w-full h-64 md:h-72 object-cover"
      />
      <div className="p-4 md:p-6  flex flex-col grow">
        <h3 className="text-xl md:text-2xl font-semibold mb-2 text-black">
          {product.name}
        </h3>
        <ul className="list-disc mb-6 mt-3">
          {product.description.map((item) => (
            <li className="text-gray-600 ml-4">{item}</li>
          ))}
        </ul>
        <div className="flex justify-between items-center mt-auto">
          <span className="text-sm md:text-xl font-bold bg black text-black">
            ${product.price} / month
          </span>
          <button
            className="bg-black  text-white font-bold px-3 py-1 md:px-4 md:py-2 rounded-full text-sm md:text-base"
            onClick={handleAdoptNow}
          >
            Get Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
