export default function Footer() {
  return (
    <footer className="mt-16">
      <hr className="h-[1px] w-5/6 mx-auto text-gray-100" />
      <div className="mx-auto py-3 flex font-semibold text-sm `justify-center items-center gap-2">
        <img src={"/images/egg2.png"} className="size-3 " />
        <p className="text-black">
          2024 Egg Robotics | 
          <a href="mailto:harshit@eggrobotics.com"> harshit@eggrobotics.com</a>
        </p>
      </div>
    </footer>
  );
}
